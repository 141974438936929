.checkbox {
  justify-content: center;
  align-items: center;
  display: flex;
  float: left;
}
.checkbox input {
  margin: 0 5%;
}
@media screen and (max-width: 900px) {
.form_v{
  width: 100% !important;
  position: relative !important;
  bottom: 2% !important;
  height: 15% !important;
}
.second_popup_v{
  height: 55% !important;
}
.vozilo_stavka{
  height: 80% !important;
  font-size:x-small;
}
.second_popup_v button{
  margin-top: 2%;
  height: 96% !important;
}
}
.treciRedv input {
  width: 100%;
}
.treciRedv {
  float: left;
  width: 30.65%;
  margin: 0 0 2% 2%;
}
.cetvrtiRedv {
  float: left;
  width: 22.47%;
  margin: 2% 0 2% 2%;
}
.form_v {
  height: 10%;
  position: fixed;
  width: 60%;
  bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(224, 221, 221);
}
.form_v button {
  height: 65%;
}

.main_popup_v {
  margin: 0%;
  margin-top: 2%;
  padding: 0 5% 0 5%;
  float: left;
  width: 100%;
  border-bottom: 1px solid rgb(224, 221, 221);
  position: relative;
  display: flex;
}

.main_popup_v textarea {
  width: 96%;
  margin: 0 2% 0 2%;
}

.second_popup_v {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 52%;
}
.second_popup_v form {
  height: 100%;
}
.vozilo_stavka {
  height: 62%;
  margin-top: 2%;
  overflow: auto;
  display: flex;
  justify-content: center;
  width: 100%;
}

@media screen and (min-width: 1600px) {
  .second_popup_v {
    height: 48%;
  }
  .vozilo_stavka {
    height: 60%;
    margin-top: 1%;
  }
  .form_v {
    height: 10%;
  }
}
.p-autocomplete {
  margin-top: 2%;
  margin-right: 0;
}
.regv {
  margin-left: 2%;
}
.main_popup_v span:first-child {
  display: inherit;
  width: 30.65%;
  margin-right: 0;
  margin-top: 0;
  margin-left: 2%;
}
.main_popup_v span {
  float: left;
}
.regv {
  margin-left: 2%;
}
@media screen and (max-height: 700px) {
  .form_v {
    bottom: 5%;
  }
}
@media screen and (max-width: 1600px) {
.form_v {
  bottom: 2%;
}
.btn-success {
  height: auto;
}
}
.vozilo_stavka thead{
position: sticky;
top: 0;
}