.servis {
  text-align: center;
  font-size: 1.5rem;
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(224, 221, 221);
}
.servis p {
  font-size: 1.5rem;
  margin: 0;
}

.servis_table {
  position: relative;
  width: 100%;
  height: 93%;
  padding: 1%;
  overflow: auto;
}
.servis_input input {
  width: 15%;
  margin: 1%;
  margin-top: 0;
}
.servis_input {
  margin-top: 0;
}

#servis_nav {
  height: 7%;
}

#servis_nav a {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  border-bottom: 1px solid rgb(224, 221, 221);
  border-right: 1px solid rgb(224, 221, 221);
}
.p-datatable {
  height: 95%;
  overflow: auto;
}

@media screen and (min-width: 1600px) {
  .p-datatable .p-datatable-tbody > tr > td {
    font-size: 15px;
    text-align: center;
    border: 1px solid #dadada;
    border-width: 1px;
    padding: 0.5%;
    outline-color: #e4e9ec;
  }
  .p-datatable .p-datatable-thead > tr > th {
    font-size: 17px;
  }
}
@media screen and (max-height: 760px) {
  #data {
    height: 82% !important;
  }
  .p-button-rounded {
    height: 70%;
  }
}

.odrzavana_vozila_item {
  float: left;
  position: relative;
  width: 16%;
  height: 100%;
  padding: 1%;
}
.odrzavana_vozila_item img {
  width: 100%;
  height: 100%;
  opacity: 0.2;
}
.vozila_odrzavana_reg {
  text-align: center;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 140%;
}
.odrzavana_vozila_item:hover {
  transform: scale(1.05);
  transition: 0.3s all linear;
}
@media screen and (max-height: 700px) {
  #data {
    height: 81% !important;
  }
}
#data {
  z-index: 100;
  position: fixed;
  width: 83.25%;
  transition: 0.4s all linear;
  height: 85%;
  background-color: white;
  border: 1px solid rgb(224, 221, 221);
  border-radius: 10px;
  overflow: hidden;
}
.header_vozila_odrzavana {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 7%;
  font-size: 170%;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid rgb(224, 221, 221);
}
#servis_nav a:hover {
  transform: scale(1.01);
}
.header_vozila_odrzavana button {
  height: 75% !important;
  width: 2.75% !important;
  border-radius: 50%;
  position: absolute;
  top: 13%;
  right: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.servis_table .p-sortable-column:first-of-type { 
  width: 5%;
}
#data h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.servis_filtered {
  padding: 1%;
  width: 100%;
  height: 96%;
  overflow: scroll;
}


.servis_filtered_item {
  float: left;
  height: 25%;
  width: 100%;
  border: 1px solid rgb(224, 221, 221);
  margin-bottom: 1%;
}
.servis_filtered_item_brnaloga {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgb(224, 221, 221);
  height: 100%;
  width: 15%;
}
.servis_filtered_item_stavke {
  float: left;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 85%;
  overflow: auto;
}
.servis_filtered_item_stavke table {
  position: absolute;
  top: 0;
  width: 100%;
  border: 1px solid rgb(224, 221, 221);
}
/* .servis_filtered_item_stavke table th {
  position: static;
} */
