.header {
  margin-top: 0;
  padding-top: 0;
  background-color: rgb(58, 57, 57);
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header p {
  height: 100%;
  line-height: 60px;
  color: white;
  text-align: center;
}
.tpservis_header {
  margin-left: 20%;
  font-weight: bold;
  font-size: 35px;
  cursor: pointer;
}
.header a {
  text-decoration: none;
  font-size: 1.5rem;
  color: rgb(31, 30, 30);
  transition: 0.3s all linear;
}

.header a::after {
  content: none;
  background-color: rgb(56, 58, 27);
}

.header a:hover {
  color: black;
  transform: scale(1.02);
  background-color: rgb(58, 57, 57);
  border-radius: 5px;
}

.header a.active {
  color: black;
  background-color: rgb(216, 219, 164);
}
.logout {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  color: white;
  margin-right: 20px;
  font-size: 25px;
  cursor: pointer;
}
.logout div:hover {
  transform: scale(1.03);
  transition: 0.3s all linear;
}
.logout p {
  font-size: small;
  position: absolute;
  z-index: 100;
  top: 0.3%;
  right: 8%;
  height: 5%;
  cursor: auto;
}

#logo {
  background-color: rgb(58, 57, 57);
  display: flex;
  height: 100%;
}
#logo img {
  height: 100%;
  background-color: rgb(58, 57, 57);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1600px) {
  .logout p {
    right: 6%;
  }
}
#logo a:hover {
  color: black;
  transform: scale(1.01);
  background-color: rgb(58, 57, 57);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 15px;
}
#logo img:hover {
  border-radius: 5px;
}