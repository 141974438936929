#loginform {
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  width: 30%;
  border-radius: 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
#loginform h3 {
  margin-bottom: 5%;
}

#loginform input:nth-child(2) {
  margin: 0 auto;
  width: 80%;
}
#loginform img {
  width: 30%;
  position: absolute;
  top: -20%;
  left: 35%;
  border-radius: 75%;
  box-shadow: inset 0 0 0 0.1rem rgba(0, 0, 0);
  background-color: #ffc107;
}
#loginform div {
  margin-bottom: 5%;
}
.p-tabmenu {
  margin-top: 120px;
}
@media screen and (max-height: 800px) {
  .p-tabmenu {
    margin-top: 100px;
  }
  #loginform button {
    font-size: medium;
  }
}
.p-tabmenu a {
  font-size: 1rem;
}
.p-tabmenu .p-tabmenu-nav {
  background: transparent;
  border: 1px solid #3f3f0b;
  border-width: 0 0 2px 0;
}
.p-tabmenu-nav {
  justify-content: center;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid #3f3f0b;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #3f3f0b transparent;
  background: none;
  color: rgba(255, 255, 255, 0.6);
  padding: 1.25rem;
  font-weight: 700;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
  height: calc(100% + 2px);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.05rem rgba(172, 185, 136, 0.5);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #3f3f0b8c;
  border-color: #6d6d13;
  color: rgba(255, 255, 255, 0.87);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #3f3f0b;
  border-color: #6d6d13;
  color: white;
}

#login button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  font-weight: 600;
}
#loginform button {
  font-size: medium;
  font-weight: 600;
  width: 30%;
  margin: 0 auto;
}
.form-label {
  margin-left: 10%;
  font-weight: 600;
}
#loginform h3 {
  margin-top: 5%;
  position: relative;
  top: -10%;
  text-align: center;
}

.anchor {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 15px;
  font-weight: 300;
  color: white;
  width: 80%;
  float: left;
  margin-left: 10%;
  cursor: pointer;
  transition: 0.3s all linear;
}

.anchor:hover {
  transform: scale(1.025);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#firstPart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10%;
  margin-left: 10%;
  margin-bottom: 0;
}
#signup button {
  margin-bottom: 2%;
}
/* #firstPart div:first-child {
  display: flex;
  width: 45%;
  flex-direction: row;
  flex: 0 0 auto;
  align-items: flex-start;
}
#firstPart div:nth-child(2) {
  display: flex;
  flex-direction: row;
  width: 45%;
  flex: 0 0 auto;
  align-items: flex-end;
} */

/* <div className="anchor" onClick={console.log('mrk')}>Don`t have an account?</div> */


@media screen and (min-width: 1600px) {
  #firstPart input {
    width: 100%;
  }
}
.inputform input {
  width: 100%;
}
#firstPart label {
  margin-left: 0;
}

@media screen and (max-width: 900px) {
  #login_screen{
    height: 100%;
  }

  #login_background{
    height: 100%;
  }
  #loginform {
 width: 95%;
  }
}
#login_background {
  display:block;
  width:100%; 
  height:100%;
  object-fit: cover;
}
