#vozni_main {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 0;
}
.vozni_park {
  text-align: center;
  font-size: 1.5rem;
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(224, 221, 221);
}
.vozni_park p {
  font-size: 1.5rem;

  margin: 0;
}
.voznipark_table {
  width: 100%;
  height: 93%;
  padding: 1%;
}
.vozni_input input {
  width: 15%;
  margin: 1%;
  margin-top: 0;
}
.vozni_input {
  margin-top: 0;
}
.datatable-style-demo .late {
  font-weight: 900;
  color: #771f1f;
  text-decoration: line-through;
}
.datatable-style-demo .verySoonExpire {
  font-weight: 900;
}
.datatable-style-demo .soonExpire {
  font-weight: 900;
}
.datatable-style-demo .expireInMonth {
  font-weight: 900;
}
.voznipark_table .p-sortable-column:first-of-type {
  width: 5%;
}

.datatable-style-demo .row-late {
  background-color: #ff5252 !important;
}
.datatable-style-demo .row-verySoonExpire {
  background-color: #da7a0c !important;
}
.datatable-style-demo .row-soonExpire {
  background-color: #ffc107 !important;
}
.datatable-style-demo .row-expireInMonth {
  background-color: #b8b6b6 !important;
}
.row-late td {
  color: black;
  font-weight: 600;
}
.row-verySoonExpire td {
  color: black;
  font-weight: 600;
}
.row-soonExpire td {
  color: black;
  font-weight: 600;
}
.row-expireInMonth td {
  color: black;
  font-weight: 600;
}
