#unos {
  width: 100%;
  height: 100%;
}

.unos {
  float: left;
  display: flex;
  justify-content: space-between;
  width: 30%;
  height: 40%;
  margin-left: 2.5%;
  margin-top: 3%;
  border-radius: 5px;
  border: 1px solid rgb(224, 221, 221);
}
.unos:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.unos h1 {
  margin-bottom: 5%;
  margin-top: 5%;
  font-size: 35px;
}

.unos {
  margin-right: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .unos:nth-child(2n-1) {
  margin-right: 3.5%;
  margin-left: 6.5%;
} */
.unos:nth-child(2n) {
  margin-left: 2.5%;
}
.unos img {
  width: 50%;
}
.unos button {
  margin: 3% auto 5% auto;
  width: 20%;
  height: 12%;
  font-size: large;
  font-weight: bold;
  color: white;
  background-color: rgb(27, 211, 42);
  border: 1px solid rgb(224, 221, 221);
  border-radius: 5px;
}

.unos_button {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
}

.unos button:hover {
  box-shadow: 1px 1px 2px black;
}
.unos button:focus {
  border: none;
  outline: none;
}
@media screen and (min-width: 1440px) {
  .unos:nth-child(1) {
    margin-top: 3%;
  }
  .unos:nth-child(2) {
    margin-top: 3%;
  }
}

@media screen and (max-width: 1250px) {
  .unos button {
    font-size: 15px;
  }
  .unos {
    margin-top: 3%;
  }
}
@media screen and (max-width: 1025px) {
  .unos button {
    font-size: 10px;
  }
}

@media screen and (min-width: 1050px) {
  .unos h1:nth-child(3) {
    font-size: 15px;
  }
}

@media screen and (min-width: 1120px) {
  .unos img {
    width: 40%;
  }
}

@media screen and (min-width: 1300px) {
  .unos h1 {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .unos img {
    width: 35%;
  }
}
@media screen and (min-width: 1440px) {
  .unos img {
    width: 35%;
  }
}
@media screen and (max-height: 700px) {
  .unos h1 {
    font-size: 30px;
  }
}

@media screen and (max-height: 750px) {
  .unos h1 {
    font-size: 30px;
  }
}
@media screen and (max-height: 700px) {
  .unos h1 {
    font-size: 23px;
  }
}


@media screen and (min-width: 1500px) {
  .unos h1 {
    margin-top: 2%;
    margin-bottom: 2%;
    width: 90%;
    
  }
}


@media screen and (max-width: 1120px) {
  .unos h1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 1300px) {
  .unos h1 {
    font-size: 25px;
    width: 70%;
  }
}
@media screen and (max-width: 1400px) {
  .unos button {
    font-size: large;
    font-weight: bold;
  }
}
@media screen and (max-width: 900px) {
  .unos{
    width: 95%;
  }
  #unos{
    height: 100%;
    overflow: scroll;
  }
  .unos button {
    width: 35%;
  }
  .unos img {
    width: 35%;
  }
}
