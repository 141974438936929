.wrapper {
  height: 100vh;
}
.workspace {
  float: left;
  height: 100%;
  width: 85%;
  position: relative;
}
@media screen and (max-width: 900px) {
  .workspace {
    float: left;
    height: 100%;
    width: 100%;
    position: relative;
  }
}

#main {
  height: -moz-calc(100% - 60px); /* Firefox */
  height: -webkit-calc(100% - 60px); /* Chrome, Safari */
  height: calc(100% - 60px); /* IE9+ and future browsers */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
