#info_main {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 0;
}

.info {
  text-align: center;
  font-size: 1.5rem;
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(224, 221, 221);
}
.info p {
  font-size: 1.5rem;
  margin: 0;
}
.info_main {
  position: relative;
  height: 100%;
}
@media screen and (max-height: 760px) {
  .info_main {
    height: 100%;
  }
  .info_alarm {
    margin: 2%;
    margin-bottom: 0;
    width: 96%;
  }
}
@media screen and (min-height: 760px) {
  
  .info_alarm {
    margin: 4%;
    margin-bottom: 0;
    width: 92%;
  }
}

.zaposleni_table {
  width: 100%;
  height: 93%;
  padding: 1%;
}
.zaposleni_input input {
  width: 15%;
  margin: 1%;
  margin-top: 0;
}
.zaposleni_input {
  margin-top: 0;
}
.footer {
  position: absolute;
  bottom: 0;
  z-index: 100;
  width: 100%;
  border-top: 1px solid rgb(224, 221, 221);
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info_alarm {
  position: relative;
  float: left;
  height: 40%;
  margin-right: 0;
  width: 44%;
  border: 1px solid rgb(224, 221, 221);
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.info_alarm section { 
  position: relative;
  margin-top: 2%;
  height: 75%;
  overflow: auto;
}
.info_alarm_critical h4 { 
  text-align: center;
  position: sticky;
}
.info_alarm_critical {
  width: 90%;
  margin: 0% 5% 3% 5%;
}

.info_alarm_header h4 { 
  text-align: center;
  position: sticky;
  margin-bottom: 0;
}
.info_alarm_header {
  width: 90%;
  margin: 3% 5% 0% 5%;
}
.info_alarm_critical p {
  width: 33%;
  float: left;
  margin-bottom: 0;
  text-align: center;
  border-right: 1px solid black;
  border-top: 1px solid black;
}
.info_alarm_critical:last-child p {
  border-bottom: 1px solid black;
  scroll-margin-bottom: 10px;
 }
 .info_alarm_critical:last-of-type {
  margin-bottom: 10px;
}

 .info_alarm_critical:nth-child(2) p {
  border-bottom: 1px solid black;
 }
 .info_alarm_critical:nth-child(3) p {
  border-top: none;
 }
 .info_alarm_critical p:first-child {
  border-left: 1px solid black;
 }
.info_alarm_critical p:nth-child(4) {
  font-weight: 700;
}
@media screen and (min-width: 1600px) {
  .info_alarm {
    margin-top: 2%;
  }
}
@media screen and (max-height: 760px) {
  .info_alarm {
    margin-top: 2%;
    width: 47%;
  }
  .info_alarm_critical {
    width: 94%;
    margin: 1.5% 3% 1.5% 3%;
  }
  .info_alarm_critical p {
    font-size: 12px;
  }
}

.message {
  margin-left: 5%;
  float: left;
}
