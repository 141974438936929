.input_kazne_kompanija {
    float: left;
    width: 20%;
    margin: 2% 0 2% 2%;
}
.faults_table .p-sortable-column:nth-of-type(4) {
    width: 60%;
}
#izvestaj_kazne_comteiner {
    text-align: left;
    font-size: 1.5rem;
    height: 8%;
    display: flex;
    justify-content: space-between;
    align-items: left;
    border-bottom: 1px solid rgb(224, 221, 221);
}
.faultsTableFirstColumn {
    font-weight: bold;
}
#dropdownFaults {
    text-align: left;
    font-size: 1.5rem;
    height: 8%;
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid rgb(224, 221, 221);
}
#dropdownFaults form {
    width: 100%;
}
.dropFault {
    float: left;
    width: 40%;
    margin: 2% 0 2% 2%;
}
#izvestaj_kazne_form_part1 {
    width: 75%;
    margin: 1% 0 1% 2%;
    display: flex;
    justify-content: left;
    align-items: center;
}
#izvestaj_kazne_form_part2 {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.kazne_izvestaj_form {
    float: left;
    width: 18%;
    margin: 2% 0 2% 2%;
}
.dropZona {
    float: left;
    width: 8%;
    margin: 2% 0 2% 2%;
}
.checkGradski {
    float: left;
    padding-top: 2%;
    margin: 1% 0 1% 2%;
}
.faults_table {
    width: 100%;
    height: 80%;
    padding: 1%;
} 
.faults_table .p-datatable-header {
    background: #f9fafb;
    color: #374151;
    border: 1px solid #e5e7eb;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: right;
}
.faults_table button {
    color: #ffffff;
    background: #22c55e;
    border: 1px solid #22c55e;
    border-radius: 50%;
}
.faults_table button:hover {
    background: #54916a;
}
.faults_input input {

    width: 15%;
    margin: 1%;
}

