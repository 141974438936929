#setting_main {
  
    position: relative;
    width: 100%;
    height: 92%;
    padding-top: 0;

}
.setting {
    text-align: center;
    font-size: 1.5rem;
    height: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgb(224, 221, 221);
  }

  .setting p {
    font-size: 1.5rem;
    margin: 0;
  }
  /* .setting_table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }
  .setting_table tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  } */
  .setting_table {
      height: 46%;
      margin-top: 1%;
      border-bottom: 1px solid rgb(224, 221, 221);
  }
  #setting_second {
      height: 45%;
      display:flex;
      justify-content: center;
      align-items: center;
  }
  .card-setting {
      width: 100%;
      background-color: rgb(241, 237, 237);
      border-bottom: 1px solid rgb(109, 108, 108);
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .card-setting button{
      border-radius: 50%;
  }
  .card-setting div{
      margin: 10px;
  }
  #setting_item {
    animation: fadeIn 1s;
    width: 40%;
    height: auto; 
    border-bottom: 1px solid rgb(109, 108, 108);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  #setting_main th:first-of-type {
    width: 5%;
  }
  #setting_main td:first-of-type {
    width: 5%;
  }
  #setting_main th:last-of-type {
    width: 5%;
  }
  #setting_main td:last-of-type {
    width: 5%;
  }
  
  .setting_table table {
    margin: 1%;
    width: 98%;
  }
  .settings_header {
    text-align: center;
    font-size: 1.5rem;
    height: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .settings_header p {
    margin: auto;
  }
  .bi-pencil-square:hover {
    transform: scale(1.2);
  }
  .bi-trash:hover {
    transform: scale(1.2);
  }
  .card-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  #setting_item footer {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .setting_table thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: #343a40;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
  } */
  #settings_nav a {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    border-bottom: 1px solid rgb(224, 221, 221);
    border-right: 1px solid rgb(224, 221, 221);
}
#settings_nav {
  height: 7%;
}
#config_workspace{
  height: 93%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#config_workspace_section {
  width: 100%;
 height: 100%;
 display: flex;
 flex-direction: column;
  align-items: center;
}
#config_workspace_section div {
  width: 50%;
}

#config_workspace section:first-of-type{
  border-right: 1px solid rgb(224, 221, 221);
}
.form_warehouse {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(224, 221, 221);
}
#config_workspace td:first-of-type{
width: 5%;
}
#config_workspace td:nth-child(3){
  width: 15%;
  }
  #config_workspace td:nth-child(4){
    width: 15%;
    }
.form_warehouse div {
  width: 50%;
  display: flex;
  justify-content: center;
}
.form_warehouse button {
  margin: 5%;
  font-size: large !important;
  font-weight: 400 !important;
}
.form_warehouse div input {
  width: 90%;
}
.warehouse_workspace {

  margin: 2.5%;
}
.warehouse_workspace table {
  width: 100%;
}