#unos_d_popup {
  position: absolute;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.274);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#unos_d_popup section {
  width: 60%;
  height: 80%;
  background-color: white;
  border: 1px solid rgb(224, 221, 221);
  border-radius: 5px;
}
@media screen and (max-width: 900px) {
  #unos_d_popup section {
    width: 98%;
    height: 98%;
  }
  .header_popup button {
    position: absolute;
    top: 3% !important;
    right: 5% !important;
}
.form{
  width: 100% !important;
  position: relative !important;
  height: 15% !important;
  bottom: 2% !important;
}
.second_popup{
  height: 48% !important;
}
.deo_stavka{
  height: 78% !important;
  font-size: x-small;
}
.second_popup button{
  margin-top: 2%;
  height: 96%;
}
}
.treciRed input {
  width: 100%;
}

.header_popup {
  float: left;
  width: 100%;
  height: 10%;
}
.header_popup h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(224, 221, 221);
  height: 100%;
  margin-bottom: 0;
}
.header_popup button {
  position: absolute;
  top: 12%;
  right: 22%;
}
.main_popup {
  margin: 0%;
  margin-top: 0;
  padding: 0 5% 0 5%;
  float: left;
  width: 100%;
  border-bottom: 1px solid rgb(224, 221, 221);
}

.second_popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 45%;
}
.second_popup form {
  height: 100%;
}
.form {
  height: 10%;
  position: fixed;
  width: 60%;
  bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(224, 221, 221);
}
.deo_stavka {
  margin-top: 2%;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: auto;
}
.deo_stavka table {
  border: 1px solid rgb(224, 221, 221);
}
.deo_stavka tbody {
  height: 40%;
}
table {
  width: 95%;
  height: fit-content;
}
td {
  height: auto;
}
table th {
  background-color: rgb(114, 114, 114);
  text-align: center;
  border: 1px solid rgb(0, 0, 0);
}
table td {
  text-align: center;
  border: 1px solid rgb(0, 0, 0);
}
table tr:nth-child(2n) {
  background-color: rgb(172, 172, 172);
}
tr {
  border-right: 1px solid rgb(0, 0, 0);
}
.btn-success {
  height: 60%;
  font-size: 150%;
}
@media screen and (min-width: 110px) {
  .second_popup {
    height: 38%;
  }
  .deo_stavka {
    height: 50%;
  }
  .form {
    height: 10%;
  }
}
.drugiDeo {
  margin: 2%;
  margin-bottom: 0;
  float: left;
  width: 46%;
}
.naziv {
  width: 96%;
  margin: 0 2% 0 2%;
}
.p-autocomplete {
  width: 96%;
  margin: 2% 2% 0 2%;
}
.main_popup textarea {
  width: 96%;
  margin: 0 2% 0 2%;
}
.treciRed {
  float: left;
  width: 30.65%;
  margin: 2% 0 2% 2%;
}
.buttonsUD {
  display: flex;
  justify-content: center;
}


.second_popup form {
  height: 20%;
  width: 100%;
  border-top: 1px solid rgb(224, 221, 221);
}

.slide-in-top-enter {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-in-top-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 400ms;
}
.slide-in-top-exit {
  transform: translateX(0%);
  transition: all 400ms;
  opacity: 0;
}
.errorInputName {
  color: red;
}
.errorInputAmount {
  color: red;
}
@media screen and (max-height: 700px) {
  .btn-success {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    font-size: 130%;
  }
  #unos_d_popup section {
    height: 90%;
  }
  .header_popup button {
    top: 7%;
  }
  .form {
    bottom: 5%;
  }
 
}
/* @media screen and (min-width: 1300px) and (max-height: 800px) {
  .main_popup {
    height: 62%;
  }
} */
@media screen and (min-height: 750px) {
  .buttonsUD button {
    margin: 2%;
    margin-bottom: 2%;
    float: left;
  }
}
@media screen and (max-height: 750px) {
  .buttonsUD button {
    
    margin: 1%;
    
  }

}
@media screen and (min-width: 1300px) and (max-height: 800px) {
 
  .deo_stavka {
    height: 33%;
    margin-top: 0.5%;
  }
}
@media screen and (min-width: 1600px) {
  .deo_stavka {
    height: 33%;
    margin-top: 0.5%;
  }
}
@media screen and (max-width: 1600px) {
  #unos_d_popup section {
    height: 95%;
  }
  .header_popup button {
    top: 5%;
}
.form {
  bottom: 2%;
}
.form button {
  height: 65% !important;
}
.btn-success {
  height: auto;
}
.deo_stavka {
  height: 70%;
}
}
.deo_stavka thead{
position: sticky;
top: 0;
}
