.cetvrtiRed {
  float: left;
  width: 25%;
  margin: 0 0 2% 2%;
}
.petiRed {
  float: left;
  width: 9.2%;
  margin: 0 0 2% 2%;
}

.treciRedr {
  float: left;
  width: 30.65%;
  margin: 0 0 2% 2%;
}

.main_popup_r {
  margin: 0%;
  height: 45%;
  margin-top: 2%;
  padding: 0 5% 0 5%;
  float: left;
  width: 100%;
  border-bottom: 1px solid rgb(224, 221, 221);
  position: relative;
}
#second_unos_goriva {
  height: 20%;
  position: relative;
}

.radni_nalog_stavka {
  margin-top: 1%;
  height: 60%;
  display: flex;
  overflow: auto;
  justify-content: center;
  width: 100%;
}

.second_popup_r {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 52%;
}
.second_popup_r form {
  height: 100%;
}
.form_r {
  height: 10%;
  position: fixed;
  width: 60%;
  bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(224, 221, 221);
}
.form_r button {
  height: 65%;
}
.main_popup_r textarea {
  width: 96%;
  margin: 2% 2% 0 2%;
}
@media screen and (min-width: 1600px) {
  .main_popup_r {
    height: 46%;
  }
  .second_popup_r {
    height: 46%;
  }
  .radni_nalog_stavka {
    height: 55%;
  }
  .form_r {
    height: 10%;
  }
}
.main_popup_r span {
  float: left;
}
.main_popup_r span input {
  width: 100%;
}
.main_popup_r span:first-of-type {
  margin-top: 0;
}
.main_popup_r textarea {
  height: 60%;
}
@media screen and (max-height: 700px) {
  .form_r {
    bottom: 5%;
  }
}
@media screen and (max-height: 750px) {
  .buttonsUR button {
    
    margin: 1%;
    
  }
}

.buttonsUR {
  width: 90%;
  position: absolute;
  bottom: 0%;
  display: flex;
  justify-content: center;
}

@media screen and (min-height: 750px) {
  .buttonsUR button {
    margin: 2%;
    margin-bottom: 2%;
    float: left;
  }
}
@media screen and (min-width: 1300px) and (max-height: 800px) {
  #board {
    height: 75%;
  }
  .main_popup_r {
    height: 54%;
  }
  .radni_nalog_stavka {
    height: 36%;
    margin-top: 1%;
  }
  .zaposleni_stavka {
    height: 45%;
    margin-top: 1%;
  }
}
@media screen and (max-width: 900px) {
  #header_popup_r h2 {
    font-size:larger;
    text-align: left;
    margin-left: 5%;
    justify-content: left;
    border-bottom: none;
  }
  #header_popup_r button:last-of-type {
    top: 3% !important;
    right: 17% !important;
  }
  #header_popup_r {
    border-bottom: 1px solid rgb(224, 221, 221);
  }
  #unos_d_popup section {
    width: 98%;
    height: 98%;
  }
  .header_popup button {
    position: absolute;
    top: 3% !important;
    right: 5% !important;
}
.form_r{
  width: 100% !important;
  position: relative !important;
  height: 15% !important;
  bottom: 2% !important;
}
.second_popup_r{
  height: 48% !important;
}
.radni_nalog_stavka{
  height: 80% !important;
  font-size: x-small;
}
.second_popup_r button{
  margin-top: 2%;
  height: 96%;
}
.main_popup_r {
  height: 40%;
}
.buttonsUR {
  bottom: 1%;
}
}
@media screen and (max-height: 700px) {
  #print_button {
    height: 2rem !important;
    width: 2rem !important;
  }
}
@media screen and (max-width: 1600px) {
  .form_r {
    bottom: 2%;
  }
  .main_popup_r{
    height:45%;
  }
  .radni_nalog_stavka{
    height: 55%;
  }
  #header_popup_r button:last-of-type {
    right: 25.5% !important;
  }
  }
  .radni_nalog_stavka thead{
  position: sticky;
  top: 0;
  }
  