.home {
  width: 80%;
  border: 2px solid rgb(224, 221, 221);
  margin: 30px auto 50px auto;
  border-radius: 15px;
}
h1 {
  margin: auto;
  width: 80%;
  text-align: center;
  border-bottom: 1px solid rgb(224, 221, 221);
}
.home div {
  width: 80%;
  margin: auto;
}

#board_input {
  height: 80px;
  width: 80%;
  margin: 1% auto 1% auto;
}

#board_unos {
  float: left;
  width: 100px;
  height: 30px;
  color: white;
  background-color: rgb(27, 211, 42);
  border: 1px solid rgb(224, 221, 221);
  border-radius: 5px;
}
#board_unos:hover {
  box-shadow: 1px 1px 2px black;
}
#board_unos:focus {
  border: none;
  outline: none;
}
.board_unos {
  height: 100%;
  padding: 0;
  float: left;
  width: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  float: left;
  width: 92%;
  height: 100%;
}
.textarea textarea {
  width: 65%;
  height: 90%;
  margin: 0;
  padding: 0;
}
/* .card-footer section:last-of-type p {
  text-align: center;
  vertical-align: middle;
  font-size: smaller;
  margin: 0;
} */
.card-bodyPlan {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 1 auto; 
  padding: 1rem 1rem;
  white-space: wrap;
  overflow:auto;
  text-overflow: ellipsis;
  height: 100%;
  
}
.card-bodyPlan-gradiant {
  background: -webkit-linear-gradient(rgb(0, 0, 0), rgb(224, 224, 224));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-text {
  width: 100%;
  text-align: left;
}
#forma_izmene {
  width: 100%;
  height: 100%;
}
.card-bodyPlan section:first-of-type {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  height: 70.5%;
}
.card-bodyPlan section:last-of-type {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.card-bodyPlan section:last-of-type {
  height: 22.5%;
  width: 100%;
}
.card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.card-footer p {
  text-align: center;
  vertical-align: middle;
  font-size: x-small;
  margin: 0;
}
.card-body textarea {
width: 100%;
}

.plan_forma_izmena {
  width: 30%;
}
.textarea div {
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.plan_dropdown {
  width: 100%;
}
#boardPR {
  position: relative;
  height: 86%;
  overflow: auto;
  width: 80%;
  margin: 10px auto 10px auto;
  text-align: center;
  -ms-overflow-style: none; 
  /* overflow-y: overlay;
  scrollbar-gutter:unset; */
}

#boardPR::-webkit-scrollbar {
  display: none;
}
.stavka_red {
  border: 1px solid rgb(224, 221, 221);
  border-radius: 5px;
  float: left;
  margin: 5px;
}
.stavka_red p {
  border-bottom: 1px solid rgb(224, 221, 221);
}
.card {
  width: 96%;
  margin: 2% 2% 0 2%;
  float: left;
  height: 24%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.5s ease-in;
}
.card:nth-child(2n-1) {
  margin-right: 0;
}
#workstation {
  height: 100%;
}
main {
  height: 100%;
}
#btnPlan {
  width: 80px;
  display: flex;
  justify-content: space-between;
}

.card-header p {
  margin-bottom: 0 !important;
}
/* #btn {
  background-color: #198754;
  border-color: #198754;
} */
.card-header {
  display: flex;
  justify-content: space-between;
}
.card-header div {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-height: 750px) {
  .home {
    margin: 1.5% auto 1.5% auto;
  }
  #boardPR {
    height: 83%;
  }
  .card {
    height: auto;
  }
}
.kolona_plan {
  width: 32.5%;
  position: sticky;
  top: 0;
  height: 95%;
  margin-right: 1.25%;
  background-color: rgba(160, 158, 158, 0.2);
  float: left;
  overflow-y: auto;
  scrollbar-width: thin !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background: #888; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
::-webkit-scrollbar-button:single-button:vertical {
  background-color: #bbbbbb;
  display: block;
  height: 5px;
  width: 10px;
}
::-webkit-scrollbar:horizontal {
  background-color: #bbbbbb;
  display: block;
  height: 7px;
  width: 10px;
}
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 10px;
  width: 10px;
  background-position: center 4px;
  border-radius: 50% 50% 0 0;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}
/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 10px;
  width: 10px;
  background-position: center;
  border-radius: 0 0 50% 50%;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
}
::-webkit-scrollbar-button:single-button:horizontal:decrement {
  height: 5px;
  width: 10px;
  background-position: center;
  border-radius: 50% 0 0 50%;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='50' fill='rgb(96, 96, 96)'><polygon points='0,25 100,0 100,50'/></svg>");
}
/* Down */
::-webkit-scrollbar-button:single-button:horizontal:increment {
  height: 5px;
  width: 10px;
  background-position: center;
  border-radius: 0 50% 50% 0;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='50' fill='rgb(96, 96, 96)'><polygon points='00,00 0,50 100,25'/></svg>");
}
/* ::-webkit-scrollbar:horizontal {
  width: 5px;
} */

/* .kolona_plan::-webkit-scrollbar {
  display: none;
} */
.kolona_plan:last-of-type {
  margin-right: 0;
}
.kolona_naslov {
  position: sticky;
  top: 0;
  font-weight: 500;
  width: 100%;
  height: 5%;
}
.kolona_naslov div {
  background-color: rgb(99, 98, 98);
  color: white;
  width: 32.5%;
  height: 100%;
  float: left;
  margin-right: 1.25%;
  display: flex;
  align-items:center;
  justify-content: center;
}
.kolona_naslov div:last-of-type {
  margin-right: 0;
}
@media screen and (max-width: 900px) {
  #workstation{
    width: 100%;
  }
  #boardPR {
    width: 100%;
  }
  #board_input{
    width: 98%;
    margin: 1%;
  }
}
#textPlan {
  overflow: auto !important;
  font-size: small;
  height: 90% !important;
 
}
.forma_izmene section:first-of-type {
  margin-bottom: 22.5%;
  overflow: auto !important;

}
@media screen and (max-width: 1600px) {
  #btnPlan button{
    scale: 0.8;
  }
}
.forma_izmene {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
