.treciRedz {
  float: left;
  width: 30.65%;
  margin: 0 0 2% 2%;
}

@media screen and (max-width: 900px) {
.form_z{
  width: 100% !important;
  position: relative !important;
  height: 15% !important;
  bottom: 2% !important;
}
.second_popup_z {
  height: 54% !important;
}
.zaposleni_stavka{
  height: 78% !important;
  font-size: x-small;
}
.second_popup_z button{
  margin-top: 2%;
  height: 96% !important;
}
.p-toast-top-right {
  top: 10px !important;
  right: 10px !important;
}
.p-toast {
  width: 20rem !important;;
}
}

.main_popup_z {
  margin: 0%;
  margin-top: 2%;
  padding: 0 5% 0 5%;
  float: left;
  width: 100%;
  border-bottom: 1px solid rgb(224, 221, 221);
}

.second_popup_z {
  float: left;
  width: 100%;
}

.main_popup_z textarea {
  width: 96%;
  margin: 2% 2% 0 2%;
}
.second_popup_z {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 52%;
}
.second_popup_z form {
  height: 100%;
}
.form_z {
  height: 10%;
  position: fixed;
  width: 60%;
  bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(224, 221, 221);
}
.form_z button {
  height: 65%;
}

.zaposleni_stavka {
  height: 63%;
  overflow: auto;
  margin-top: 1%;
  display: flex;
  justify-content: center;
  width: 100%;
}
@media screen and (min-width: 1600px) {
  .second_popup_z {
    height: 46%;
  }
  .zaposleni_stavka {
    margin-top: 1%;
    height: 63%;
  }
  .form_z {
    height: 10%;
  }
}
.main_popup_z span {
  float: left;
}
.main_popup_z span:first-of-type input {
  width: 100%;
}
.main_popup_z span:first-of-type {
  display: inherit;

  margin-top: 0;
}
@media screen and (max-height: 700px) {
  .form_z {
    bottom: 5%;
  }
}
@media screen and (max-width: 1600px) {
  .form_z {
    bottom: 2%;
  }
  .zaposleni_stavka{
    height: 65%;
  }
  }
  .zaposleni_stavka thead{
  position: sticky;
  top: 0;
  }