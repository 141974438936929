.magacin_child {
  position: relative;
  width: 100%;
  height: 92%;
  padding: 1%;
  padding-top: 0;
}
#magacin_nav {
  height: 7%;
}
.magacini {
  width: 100%;
  height: 7%;
}

.magacin_child input {
  width: 15%;
  margin: 1%;
  margin-top: 0;
}

#magacin_nav a {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100%;
  border-bottom: 1px solid rgb(224, 221, 221);
  border-right: 1px solid rgb(224, 221, 221);
}
#magacin_nav a:hover {
  transform: scale(1.01);
}
.p-datatable {
  height: 95%;
  overflow: auto;
}
.magacin_child .p-sortable-column:first-of-type {
  width: 5%;
}
.magacin_child .p-sortable-column:nth-of-type(2) {
  width: 25%;
}

@media screen and (min-width: 1600px) {
  .p-datatable .p-datatable-tbody > tr > td {
    font-size: 15px;
    text-align: center;
    border: 1px solid #dadada;
    border-width: 1px;
    padding: 1px;
    outline-color: #e4e9ec;
  }
  .p-datatable .p-datatable-thead > tr > th {
    font-size: 15px;
  }

}
@media screen and (max-height: 760px) {
  .p-datatable {
    height: 93%;
  }

}
.p-paginator {
  position: sticky;
  bottom: 1px;
  border-top: 1px solid rgb(224, 221, 221);;
}
.p-datatable-wrapper thead {
  position: sticky;
  top: -1px;
  border-top: 1px solid rgb(224, 221, 221);;
}
.datatable-style-demo .outofstock {
  font-weight: 900;
  color: #771f1f;
  text-decoration: line-through;
}
.datatable-style-demo .verylowstock {
  font-weight: 900;
  color: #ff5252;
}
.datatable-style-demo .lowstock {
  font-weight: 900;
  color: #ffc107;
}
.datatable-style-demo .instock {
  font-weight: 900;
  color: #44c744;
}

.datatable-style-demo .row-outofstock {
  background-color: #ff5252 !important;
}
.row-outofstock td {
  color: black;
  font-weight: 600;
}
