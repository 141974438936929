#gorivo_nav {
    height: 7%;
}

#gorivo_nav a {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    border-bottom: 1px solid rgb(224, 221, 221);
    border-right: 1px solid rgb(224, 221, 221);
}
#gorivo_nav a:hover {
    transform: scale(1.01);
  }