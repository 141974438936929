#pumpe_forma {
    width: 100%;
    height: 55%;
    transition: all 0.5s ease-in;
    position: relative;
    opacity: 1;
}
#fuel_window_close_button {
    top: 1%;
    right: 2%;
}
#pumpe_forma h3 {
    margin-top: 1%;
    margin-bottom: 1%;
    text-align: center;
    position: relative;
}
.main_popup_g {
    margin: 0%;
    height: 45%;
    margin-top: 2%;
    padding: 0 5% 0 5%;
    float: left;
    width: 100%;
    border-bottom: 1px solid rgb(224, 221, 221);
    position: relative;
}
.main_popup_g span:first-child input {
width: 100%;
}
.main_popup_g .komentar {
    margin: 0 0 2% 2%;
}
.buttonsUG {
    width: 90%;
    position: absolute;
    bottom: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#stanje_pumpi {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 2.5% 0 2.5% 0;
}
#stanje_pumpi_forma {
  width: 100%;
  height: 20%;
  border-bottom: 1px solid rgb(224, 221, 221);
  float: left;
  display: flex;
  align-items: center;

}
#stanje_pumpi p{
  font-size: 25px;
  margin-bottom: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}
.second_popup_g {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 80%;
    transition: all 0.5s ease-in;
}
@media screen and (min-width: 1600px){
.sipanja_stavka {
    margin-top: 0.5%;
    height: 85%;
}
}

.sipanja_stavka {
    height: 100%;
    overflow: auto;
    margin-top: 1%;
    display: flex;
    justify-content: center;
    width: 100%;
    
}
/* @media screen and (min-width: 1300px) and (max-height: 800px) {
    .main_popup_g {
      height: 46%;
    }
  } */
  @media screen and (max-height: 700px) {
    .sipanja_stavka {
      height: 70%; 
  }
  }
  @media screen and (min-height: 750px) {
    .buttonsUG button {
      margin: 2%;
      margin-bottom: 2%;
      float: left;
    }
  }
  @media screen and (max-height: 750px) {
    .buttonsUG button {
      
      margin: 1%;
      
    }
  }
  #stanje_pumpi_table {
    position: relative;
    top: 30%;
  }
  #stanje_pumpi_table table{
    position: absolute;
    top: 2.5%;
    width: 95%;
    margin: 2.5%;
  }
  #stanje_pumpi_table table td:first-of-type {
    width: 5%;
  }
  #stanje_pumpi_table table td:nth-child(3) {
    width: 20%;
    font-size: large;
    font-weight: 500;
  }
