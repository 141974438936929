#zaposleni_main {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 0;
}

.zaposleni_pregled {
  text-align: center;
  font-size: 1.5rem;
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(224, 221, 221);
}
.zaposleni_pregled p {
  font-size: 1.5rem;
  margin: 0;
}

.zaposleni_table {
  width: 100%;
  height: 93%;
  padding: 1%;
}
.zaposleni_input input {
  width: 15%;
  margin: 1%;
  margin-top: 0;
}
.zaposleni_input {
  margin-top: 0;
}
