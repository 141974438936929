.stavka_vozilo {
   
    display: flex;
    
    align-items: center;
    width: fit-content;
    
}
#izvestaj_poruka {
    margin-left: 1%;
}
.stavka_vozilo div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(224, 221, 221);
    width: 10%;
    height: 100%;
}
#gorivo_izvestaj_workspace {
  display: flex;
  flex-direction: column;
  height: 91%;
}
@media screen and (min-height: 750px){
    #gorivo_izvestaj_workspace {
        height: 91%;
      } 
}

.red_gorivo_izvestaj_form {
    float: left;
width: 15%;
margin: 2% 0 2% 2%;
}
#izvestaj_gorivo_form{
    width: 100%;
}
#second_izvestaj_view {
    margin-left: 1%;
    width: 98%;
    overflow:auto;
    overflow-wrap: inherit;
    height: 0%;
    opacity: 0;
    transition: 1s linear ease-in;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.stavka_vozilo:first-child {
    
    height: 50px;
    display: flex;
    border-top: 1px solid rgb(224, 221, 221);
    border-bottom: 3px solid rgb(126, 125, 125);
    align-items: center;
    
}
.stavka_vozilo div:nth-last-child(2) {
    
    background-color: blanchedalmond;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    border-left: 3px solid rgb(126, 125, 125);
}
.stavka_vozilo div:nth-child(3) {
border-right: 3px solid rgb(126, 125, 125);
}

.stavka_vozilo div:last-child {
    
    background-color: blanchedalmond;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    text-align: center;
}
.stavka_vozilo p {
    
   height: 50%;
   width: 100%;
   margin: 0;
   border-bottom: 1px solid rgb(224, 221, 221);
   display: flex;
   justify-content: center;
   align-items: center;
    
}
.stavka_vozilo div{
    height: 50px;
    width: 100px;
    text-align: center;
}
.stavka_vozilo:nth-child(2n-1){
    background-color: rgb(172, 172, 172);
}
.stavka_celija {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.stavka_celija span{
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    height: 25px;
    border: 1px solid rgb(163, 162, 162);
    
}
.stavka_celija main {
    width: 250px;
    height: 25px;
}
.suma_gorivo_naslovi {
    width: 300px;
    display: flex;
   flex-direction: column;
}
.suma_gorivo_naslovi_child {
    float: left;
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center; */
}
.summary_view {
    display: flex;
    flex-direction: column;
    width: 350px;
    justify-content: center;
    align-items: center;
    height: 350px;
}
#stanje_pumpe {
    height: 80px;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#celija_gorivo_dole {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}
#celija_gorivo_dole span{
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    height: 50px;
    border: 1px solid rgb(163, 162, 162);
    
}
#celija_gorivo_dole main {
    width: 250px;
    height: 50px;
}
