.sidebar {
  position: relative;
  height: 100%;
  width: 15%;
  float: left;
  display: flex;
  background-color: azure;
  flex-direction: column;
  align-items: center;
  border-right: 3px solid rgb(224, 221, 221);
}
@media screen and (max-width: 900px) {
  .sidebar{
    display: none;
  }
}

.button_sidebar {
  display: flex;
  width: 100%;
  text-decoration: none;
  height: 8%;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(224, 221, 221);
}

a {
  text-decoration: none;
  font-size: 1.5rem;
  color: rgb(31, 30, 30);
  transition: 0.3s all linear;
}

a:active {
  background-color: rgb(209, 200, 79);
  transition: 0.3s all linear;

  /* transform: scale(1.02); */
}

a::after {
  content: none;
  background-color: rgb(56, 58, 27);
}

a:hover {
  color: black;
  transform: scale(1.03);
  background-color: rgb(217, 218, 200);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

a.active {
  color: black;
  background-color: rgb(216, 219, 164);
}

.button_sidebar:last-child {
  margin-top: auto;
  border-top: 1px solid rgb(224, 221, 221);
  border-bottom: none;
  height: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button_sidebar:last-child a {
  float: left;
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid rgb(224, 221, 221);

}

.button_sidebar:first-child {
  border-bottom: 2px dotted rgb(224, 221, 221);
  border-top: none;
  height: 15%;
  font-size: 2.25rem;

}

.button_sidebar:first-child:active {
  transform: scale(1.05);
}

.first {
  border-bottom: 2px dotted rgb(224, 221, 221);
  border-top: none;
  height: 15%;
  font-size: 2.25rem;
}

.alarm {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 5%;
  right: 3%;
  width: 10%;
  height: 2%;
  z-index: 100;
  color: white;
  font-weight: 400;
  font-size: small;
  border-radius: 50%;
  background-color: rgb(214, 59, 59);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media screen and (min-width: 1600px) {
  .alarm {
    width: 8%;
    height: 2.5%;
    bottom: 4.5%;
  }
}
@media screen and (max-height: 760px) {
  .alarm {
    width: 7%;
    height: 2.5%;
    bottom: 4.5%;
    font-size:xx-small;
  }
}