#summary {
 display: flex;
 align-items: flex-start;
 justify-content: center;
 margin: 0 auto;
 flex-direction: column;
 width: 50%;
 height: 100%;
}
#summary_image {
    width: 100%;
    height: 100%;
    opacity: 0.07;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

