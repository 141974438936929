@media screen and (max-width: 900px) {
    #unos_g_popup section {
      width: 98% !important;
      height: 98% !important;
    }
    .main_popup_g {
        height: 35% !important;
    }
    #stanje_pumpi p {
        font-size: small !important;
        text-align: center;
        flex-direction: column;
    }
    .gorivo_red:first-child {
        margin-left: 2% !important;
    }
    #unos_g_popup section {
      float: left;
    }
  #form_g{
    width: 100% !important;
    position: sticky !important;
    background-color: white;
    height: 15% !important;
    bottom: 2% !important;
  }
  .second_popup_g{
    height: 100% !important;
  }
  .sipanja_stavka{
  height: 80%;
    font-size: x-small;
    transition: 1s linear ease-in;
  }
  .second_popup_g button{
    margin-top: 2%;
    height: 96%;
  }
  }
  #stanje_pumpi_forma input {
    width: 20%;
  }
  #stanje_pumpi_forma span {
    width: 20%;
  }
  #stanje_pumpi_forma div {
    width: 20%;
  }


#dopuna_pumpe_button {
 margin-left: 2%;
}
.gorivo_red {
    width: 19%;
    margin: 1%;
    margin-right: 0;
}
.gorivo_red:first-child {
    margin-left: 4%;
}
#pumpa_button {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* #stanje_pumpi p:first-child {
    margin-left: 10%;
}
#stanje_pumpi p:last-child {
    margin-right: 10%;
} */
#unos_g_popup {
    position: absolute;
    z-index: 101;
    background-color: rgba(0, 0, 0, 0.274);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
#unos_g_popup section {
    width: 60%;
    height: 80%;
    background-color: white;
    border: 1px solid rgb(224, 221, 221);
    border-radius: 5px;
    position: relative;
}
@media screen and (max-height: 700px) {
    #unos_g_popup section {
        height: 90%;
    }
    #form_g {
        bottom: 2% !important;
      }

  }
  #toggle_fuel_window {
    position: absolute;
    z-index: 101;
    top: 3%;
    left: 2.5%;
}
@media screen and (max-width: 900px){
#toggle_fuel_window {
    top: 3.5% !important;
    left: 5% !important;
}
}
#toggle_fuel_window span {
  background: #afd3c8; 
}
#toggle_fuel_window span::before {
  background: #385048;; 
}
#form_g {
    height: 10%;
    position: fixed;
    width: 60%;
    bottom: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgb(224, 221, 221);
  }
.header_popup_g h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgb(224, 221, 221);
    height: 100%;
    margin-bottom: 0;
  }
  .header_popup_g button {
    position: absolute;
    top: 2.5%;
    right: 2.5%;
    z-index: 101;
  }
  .header_popup_g {
    height: 10%;
    width: 100%;
  }
  @media screen and (max-width: 1600px) {
    #unos_g_popup section {
      height: 95%;
    }
    #form_g {
      bottom: 2%;
    }
  
}
  .main_popup_g {
    height: 40% !important;
  }
  .btn-success {
    height: auto;
  }
  .sipanja_stavka {
    height: 80%;
  }
  .sipanja_stavka thead{
  position: sticky;
  top: 0;
  }
  @media screen and (max-width: 900px){
    .header_popup_g button {
      position: absolute;
      top: 3% !important;
      right: 5% !important;
    }
  }
  #form_g button {
    height: 65%;
}
 